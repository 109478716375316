import React from 'react'
import '../styles/about.css';
import aboutImage from '../assets/images/about-image.avif'
const About = () => {
    return (
        <div className="Acontainer">
          <h1 className="Aheading">About Us</h1>
          <img className="Aimage" src={aboutImage} alt="About Us" />
          <p className="Atext">Welcome to our tour and travel website! We're dedicated to helping you plan your next adventure. Whether you're looking for a relaxing beach vacation or an exciting city tour, we've got you covered.</p>
          <p className="Atext">Our team of experienced travel experts is here to help you every step of the way. From finding the perfect destination to booking your flights and accommodations, we'll take care of everything so you can focus on enjoying your trip.</p>
          <p className="Atext">At our company, we believe that travel is not just about seeing new places, but also about experiencing new cultures and meeting new people. That's why we offer a wide range of tours and activities that allow you to immerse yourself in the local culture and connect with the people who call your destination home.</p>
          <p className="Atext">So whether you're a seasoned traveler or a first-time explorer, we invite you to join us on your next adventure. Let us help you create memories that will last a lifetime!</p>
        </div>
      );
}

export default About
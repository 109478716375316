import React from 'react';
import Slider from 'react-slick';
import ava01 from '../../assets/images/ava-1.jpg'
import ava02 from '../../assets/images/ava-2.jpg'
import ava03 from '../../assets/images/ava-3.jpg'
const Testimonials = () => {

    const settings={
        dots:true,
        Infinite:true,
        autoplay:true,
        speed:1000,
        swipeToSlide:true,
        autoplaySpeed:2000,
        slidesToShow:3,


        responsive:[
            {
            breakpoint:992,
            settings:{
                slidesToShow:2,
                slidesToScroll:1,
                infinite:true,
                dots:true,
            },
            },
            {
                breakpoint:576,
                settings:{
                    slidesToShow:1,
                    slidesToScroll:1,
                },
                },
        ]
    }
  return <Slider {...settings}>
    <div className="testimonial py-4 px-3">
       <p>Wow! This tour and travel website exceeded all my expectations! From the moment I landed on the website, 
        I was blown away by the stunning destinations and immersive experiences they offered. The booking process was a breeze,
         and their customer service team went above and beyond to ensure every detail of my trip was perfect. 
         The personalized attention I received made me feel like a VIP traveler. The memories I created on their curated journey
          will stay with me forever. I can't recommend this website enough. 
        If you're looking for an unforgettable adventure, look no further!</p>

         <div className="d-flex align-items-center gap-4 mt-3">
            <img src={ava01} className='w-25 h-25 rounded-2' alt="" />
            <div>
                <h6 className='mb-0 mt-3'>Jhon Doe</h6>
                <p>Customer</p>
            </div>
         </div>
    </div>

    <div className="testimonial py-4 px-3">
       <p>Hands down, the best tour and travel website I've ever come across! The level of customization and attention
         to detail is unmatched. I was able to design my dream itinerary, choosing the destinations, activities, and 
         accommodations that suited my preferences perfectly. The website's interface is intuitive and user-friendly, 
         making the booking process a breeze.The trip itself was beyond incredible -
          every moment was meticulously planned, and the experiences were truly unique.</p>

         <div className="d-flex align-items-center gap-4 mt-3">
            <img src={ava02} className='w-25 h-25 rounded-2' alt="" />
            <div>
                <h6 className='mb-0 mt-3'>Lia Franklin</h6>
                <p>Customer</p>
            </div>
         </div>
    </div>

    <div className="testimonial py-4 px-3">
       <p>I can't stop raving about this tour and travel website! It's like they have a sixth sense for what travelers want. 
        The destinations they feature are simply breathtaking, and the itineraries are thoughtfully designed to capture the 
        essence of each place. The website's user experience is exceptional, allowing me to easily browse and compare different 
        options. The customer service team was incredibly helpful and responsive, providing valuable insights and personalized 
        recommendations. Thanks to their expertise, my trip was filled with unforgettable moments 
        and remarkable experiences. I'm already planning my next getaway with this incredible website!</p>

         <div className="d-flex align-items-center gap-4 mt-3">
            <img src={ava03} className='w-25 h-25 rounded-2' alt="" />
            <div>
                <h6 className='mb-0 mt-3'>Jhon Doe</h6>
                <p>Customer</p>
            </div>
         </div>
    </div>

    <div className="testimonial py-4 px-3">
       <p>I had high expectations when I stumbled upon this tour and travel website, and they were exceeded in every way. 
        The website's attention to detail and commitment to quality shine through in every aspect. The booking process was smooth, 
        and the communication was prompt and informative. The trip itself was nothing short of magical. The accommodations were 
        luxurious, the activities were thrilling, and the guides were passionate and knowledgeable. 
        This website has completely transformed the way I travel, and I can't imagine planning a trip without their expertise.</p>

         <div className="d-flex align-items-center gap-4 mt-3">
            <img src={ava02} className='w-25 h-25 rounded-2' alt="" />
            <div>
                <h6 className='mb-0 mt-3'>Lia Franklin</h6>
                <p>Customer</p>
            </div>
         </div>
    </div>
  </Slider>
  
};

export default Testimonials;
import React from 'react';
import ServiceCard from './ServiceCard';
import { Col } from 'reactstrap';
import weatherImg from '../assets/images/weather.png';
import guideImg from '../assets/images/guide.png';
import customizationImg from '../assets/images/customization.png';


const servicesData = [
    {
    imgUrl : weatherImg,
    title : "Calculate weather",
    desc : "Discover the art of precision in travel. At travel world, we harness the science of weather calculations to unlock unforgettable adventures. Our expert forecasts guide you to destinations with ideal conditions, where every moment is bathed in sunshine or embraced by gentle rain. Trust our meticulous planning to ensure your journey aligns perfectly with your weather preferences. With us, explore confidently under clear skies or amidst the magic of changing weather. Let our expertise in weather calculation unlock extraordinary experiences tailored just for you."
    
  
  },
    {
        imgUrl : guideImg,
        title : "Best Tour Guide",
        desc : "Experience the essence of extraordinary journeys with our exceptional tour guides. Our passionate experts will lead you through captivating destinations, unveiling hidden gems and sharing captivating stories along the way. Let us be your trusted companion, offering insights, local knowledge, and personalized attention, ensuring every step of your adventure is unforgettable. Discover the world with confidence, knowing that our dedicated tour guides will create cherished memories that will last a lifetime."
    },
        
    {
            imgUrl : customizationImg,
            title : "Customization",
            desc : "Tailor-made experiences crafted just for you. Our customization services empower you to design your dream journey, reflecting your unique preferences and desires. From selecting handpicked destinations to curating personalized itineraries, we put you in control of every detail. Whether it's an adventurous escapade, a serene retreat, or a cultural immersion, our team will work closely with you to ensure every aspect of your trip is precisely tailored to your tastes. Experience travel like never before with our bespoke customization services, creating memories that are truly yours."
    },
]
const ServiceList = () => {
  return (<>
  
  {servicesData.map((item,index)=> (
  <Col lg='3' md='6' sm='12' className='mb-4' key={index}>
    <ServiceCard item={item}/>
    </Col>
    ))}
  </>
  );
};

export default ServiceList;
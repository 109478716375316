import React from 'react';
import '../styles/home.css'
import { Container, Row, Col } from 'reactstrap';
import heroImg from '../assets/images/hero-img01.jpeg';
import heroImg02 from '../assets/images/hero-img02.jpg';
import heroVideo from '../assets/images/hero-video.mp4';
import worldImg from '../assets/images/world.png';
import experienceImg from '../assets/images/experience.png'

import Subtitle from './../shared/Subtitle';
import ServiceList from '../services/ServiceList';
import SearchBar from '../shared/SearchBar';
import FeaturedTourList from '../components/Featured-tours/FeaturedTourList';
import MasonryImagesGallery from '../components/Image-gallery/MasonryImagesGallery';
import Testimonials from '../components/Testimonial/Testimonials';
import Newsletter from '../shared/Newsletter';
const Home = () => {
  return <>

  {/* ======hero section start ======= */}
  <section>
    <Container>
      <Row>
        <Col lg='6'>
          <div className="hero__content">
            <div className='hero__subtitle d-flex align-items-center'>
              <Subtitle subtitle={'Know Before You Go'}/>
              <img src={worldImg} alt="" />
              </div>
              <h1>Traveling opens the door to creating{" "} <span className="highlight"> memories</span></h1>
              <p>Welcome to our tour and travel agency! We specialize in creating unforgettable travel experiences for our clients. 
                Whether you're looking for an adventurous hike through the mountains or a relaxing beach vacation, we've got you covered.
                Our trained staff will help you. Design with you a customized itinerary that suits your specific needs and preferences. 
                From transportation to accommodation and activities, we take care of all the details so you can just relax 
                and enjoy your trip.Let us help you plan your next unforgettable adventure!</p>
          </div>
        </Col>
        <Col lg='2'>
          <div className="hero__img-box">
            <img src={heroImg} alt="" />
          </div>
        </Col>
        <Col lg='2'>
          <div className="hero__img-box  hero__video-box mt-4">
            <video src={heroVideo} alt="" controls/>
          </div>
        </Col>
        <Col lg='2'>
          <div className="hero__img-box mt-5">
            <img src={heroImg02} alt="" />
          </div>
        </Col>
        <SearchBar/>
      </Row>
    </Container>
  </section>
  {/* ======hero section start ======= */}
  <section>
    <Container>
      <Row>
        <Col lg='3'>
          <h5 className='services__subtitle'>What we serve</h5>
          <h2 className='services__title'>We offer our best services</h2>
        </Col>
        <ServiceList/>
      </Row>
    </Container>
  </section>


  {/* ====== featured tours section start ====== */}
    <section>
      <Container>
        <Row>
          <Col lg='12' className="mb-5">
            <Subtitle subtitle={'Explore'}/>
            <h2 className="featured__tour-title">Our featured tours</h2>
          </Col>
          <FeaturedTourList/>
        </Row>
      </Container>
    </section>
   {/* ====== featured tours section end ====== */}

   {/* ====== experience section start========= */}
<section>
  <Container>
    <Row>
      <Col lg='6'>
        <div className="experience__content">
          <Subtitle subtitle={'Experience'}/>

          <h2>With our all experience <br/>we will serve you</h2>
          <p>Travelling — it leaves you speechless,  
            <br/>
            then turns you into a storyteller.</p>
        </div>
        <div className="counter__wrapper d-flex align-items-center gap-5">
          <div className="counter__box">
            <span>12k+</span>
          <h6>Successful Trip</h6>
          </div>

          <div className="counter__box">
            <span>2k+</span>
          <h6>Regular clients</h6>
          </div>
          
          <div className="counter__box">
            <span>15</span>
          <h6>Years experience</h6>
          </div>
        </div>
      </Col>
      <Col lg='6'>
        <div className="experience__img">
          <img src={experienceImg} alt="" />
        </div>
      </Col>
    </Row>
  </Container>
</section>
    {/* ====== experience section end========= */}


    {/* ========gallery section start ======== */}
    <section>
      <Container>
        <Row>
          <Col lg='12'>
            <Subtitle subtitle={'Gallery'}/>
            <h2 className="gallery__title">Visit our customers tour gallery</h2>
          </Col>
          <Col lg='12'>
            <MasonryImagesGallery/>
          </Col>
        </Row>
      </Container>
    </section>
    {/* ========gallery section end ======== */}
    {/* =========testimonal section start====== */}
    <section>
      <Container>
        <Row>
          <Col lg='12'>
            <Subtitle subtitle={'Fans Love'}/>
            <h2 className="testimonial__tile">What our fans say about us</h2>
          </Col>
          <Col lg='12'>
            <Testimonials/>
          </Col>
        </Row>
      </Container>
    </section>
    {/* =========testimonal section start====== */}
    <Newsletter/>
  </>
  
};

export default Home;